import React from 'react';
//import { Card } from "react-bootstrap";
//import { Button } from "react-bootstrap";
//import {Link} from "react-router-dom";
//import homework_img from "./assets/homework-manager.jpg";
//import digital_fit from "./assets/digital_fit.png";
//import embeder from "./assets/embeder.jpg";
//import reflex from "./assets/reflex.jpg";

function Skills() {
  document.title = "Skills - Tinu Vanapamula";

  return (
    <div className="content">
      <h1 className="heading">My Skills</h1>

      <div className = "skills-div">
          
      </div><br/>
    </div>
  );
}

export default Skills;
